/* Styles go here */
.body-container {
  margin-top: 4rem;
}

.token {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: inline-block;
  background-color: black;
}

body {
  margin: 0;
}
